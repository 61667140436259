import React from 'react';
import * as Styled from './ProgresBarStyles';

type ProgressBarProps = {
  startProgress: number;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ startProgress }) => {
  return <Styled.ProgressBarItem style={{ transform: `translateX(50%) scaleY(${startProgress})` }} />;
};

export default ProgressBar;
