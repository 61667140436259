import React from 'react';
import { BooksProps } from 'models/BooksProps';

const Video: React.FC<BooksProps> = ({ books, selectedIndex }) => {
  let isSelected = selectedIndex;
  const { trailer } = books[isSelected];

  return (
    <iframe
      src={`https://www.youtube.com/embed/${trailer}`}
      title={books[isSelected].title}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      allowFullScreen
      width="100%"
      height="100%"
    />
  );
};

export default Video;
