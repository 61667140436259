import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Media } from 'config/CONSTANTS';

const visibleVariants = {
  visible: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.5, delayChilden: 0.2, staggerChildren: 0.1 },
  },
  hidden: {
    y: 100,
    opacity: 0,
  },
};

export const CoverImageInner = styled.div`
  position: relative;
  width: 200px;
  height: 310px;

  @media (min-width: ${Media.TABLET}) {
    width: 320px;
    height: 340px;
  }

  @media (min-width: ${Media.DESKTOP}) {
    transform: translateX(-50%);
    width: 230px;
    height: 250px;
  }

  @media (min-width: 1440px) {
    width: 250px;
    height: 290px;
  }

  @media (min-width: 1680px) {
    width: 300px;
    height: 360px;
  }
`;

export const CoverImageWrapper = styled(motion.div).attrs(() => ({
  initial: 'hidden',
  variants: visibleVariants,
}))`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: 30px 30px 80px 0 rgba(20, 23, 28, 0.2);
`;
