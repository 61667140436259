import React from 'react';
import Img from 'gatsby-image';
import * as Styled from './CoverImageStyles';
import { BooksProps } from 'models/BooksProps';
import { useIntl } from 'gatsby-plugin-intl';

const CoverImage: React.FC<BooksProps> = ({ books, selectedIndex }) => {
  const { locale } = useIntl();

  return (
    <Styled.CoverImageInner>
      {books.map((book: object, index: number) => {
        const { fluid } = book[locale].cover.localFile.childImageSharp;
        const { alt_text } = book[locale].cover;
        let isSelected = index === selectedIndex;
        return (
          book[locale].cover && (
            <Styled.CoverImageWrapper key={index} animate={isSelected ? 'visible' : 'hidden'}>
              <Img fluid={fluid} alt={alt_text} />
            </Styled.CoverImageWrapper>
          )
        );
      })}
    </Styled.CoverImageInner>
  );
};

export default CoverImage;
