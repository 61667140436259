import styled from 'styled-components';
import { Colors, Media } from 'config/CONSTANTS';
import { motion } from 'framer-motion';

const modalVariant = {
  initial: { opacity: 0 },
  modalVisible: { opacity: 1 },
  exit: { opacity: 0 },
};
const containerVariant = {
  initial: { top: '-50%', transition: { type: 'spring' } },
  modalVisible: { top: '50%' },
  exit: { top: '-50%' },
};

const modalHeight = {
  height: `45%`,
}

export const ModalInner = styled(motion.div).attrs(() => ({
  initial: 'initial',
  variants: modalVariant,
}))`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 100;
`;

export const ModalItem = styled(motion.div).attrs(() => ({
  initial: 'initial',
  variants: containerVariant,
}))`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${Colors.LIGHT};
  border-radius: 10px;
  width: 80%;
  height: ${modalHeight.height};
  padding: 15px;

  @media (min-width: ${Media.DESKTOP}) {
    width: 50%;
    height: 50%;
    padding: 20px;
  }

  @media (max-width: 499px) {
    width: 80%;
    height: 30%;
    padding: 10px;
  }
`;

export const ModalCloseBtn = styled.button`
  width: 48px;
  height: 48px;
  background-color: ${Colors.DARK};
  border: 0;
  border-radius: 50%;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  outline: none;
  cursor: pointer;
  transition: transform 0.3s ease;

  &::before,
  &::after {
    content: '';
    width: 20px;
    height: 2px;
    background-color: ${Colors.LIGHT};
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(135deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-135deg);
  }

  &:hover {
    transform: translate(50%, -50%) rotate(180deg);
  }
`;

export const ModalContent = styled.div`
  overflow-y: auto;
  height: 100%;
  padding-right: 20px;

  &::-webkit-scrollbar {
    width: 1px !important;
    background: ${Colors.PRIMARY};
    height: 30px;
    display: block;
  }

  &::-webkit-scrollbar-track {
    background-color: ${Colors.GRAY};
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${Colors.PRIMARY};
    height: 20px;
    width: 3px;
  }
`
