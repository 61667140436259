import React from 'react';
import * as Styled from './ModalStyles';

type ModalProps = {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const Modal: React.FC<ModalProps> = ({ children, onClick }) => {
  return (
    <Styled.ModalInner animate={'modalVisible'} exit={'exit'}>
      <Styled.ModalItem>
        <Styled.ModalContent>
          {children}
        </Styled.ModalContent>
        <Styled.ModalCloseBtn onClick={onClick} />
      </Styled.ModalItem>
    </Styled.ModalInner>
  );
};

export default Modal;
