import React, { useEffect, useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import MainTemplate from 'templates/MainTemplate';
import { Helmet } from 'react-helmet';
import SectionContent from 'components/SectionContent/SectionContent';
import {
  ContentContainer,
  ContentInner,
  ContentRow,
  ContentWrapper,
} from 'components/SectionContent/SectionContentStyles';
import { getDefaultIndex, useBooksQuery } from 'hooks/use-books-query';
import HeroImage from 'components/HeroImage/HeroImage';
import Quote from 'components/Quote/Quote';
import BackgroundImage from 'components/BackgroundImage/BackgroundImage';
import CoverImage from 'components/CoverImage/CoverImage';
import Modal from 'components/Modal/Modal';
import Video from 'components/Video/Video';
import * as Styled from 'components/ArrowNav/ArrowNav';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import ArrowIcon from 'assets/arrow.svg';
import { SectionHeading } from '../components/SectionHeading/SectionHeading';
import { useContactQuery } from '../hooks/use-contact-query';

const Home = ({ location }) => {
  const books = useBooksQuery();
  const contactData = useContactQuery();
  const { formatMessage, locale } = useIntl();
  const defaultIndex = getDefaultIndex();
  const [selectedIndex, setSelectedIndex] = useState<number>(defaultIndex);
  const [scrollThrottle, setScrollThrottle] = useState<boolean>(false);
  const touchStartY: number = 0;
  const [startProgress, setStartProgress] = useState<number>(0.25);
  const selectedModal = {
    modalVideo: 1,
    modalDescription: 2,
  }
  const [modalVisible, setModalVisible] = useState<number | null>(null);
  const isLastIndex = books.length - 1;

  const replaceMessanger = contactData && contactData[locale].messanger;

  useEffect(() => {
    setSelectedIndex(0);
    if (location && location.state && location.state.bookId) {
      setSelectedIndex(location.state.bookId);
      setStartProgress((location.state.bookId + 1) * 0.25);
    }
  }, [location]);


  const handleScroll = (scrollingDown: boolean) => {
    if (scrollingDown) {
      const isLastIndex = selectedIndex + 1 === books.length;
      const newIndex = isLastIndex ? selectedIndex : selectedIndex + 1;
      if (startProgress < 1) {
        setStartProgress(state => state + 0.25);
      }
      setSelectedIndex(newIndex);
    } else {
      const isFirstIndex = selectedIndex === 0;
      const newIndex = isFirstIndex ? selectedIndex : selectedIndex - 1;
      if (startProgress > 0.25) {
        setStartProgress(state => state - 0.25);
      }
      setSelectedIndex(newIndex);
    }

    setScrollThrottle(true);
    setTimeout(() => {
      setScrollThrottle(false);
    }, 500);
  };

  const handleWheel = (event: React.WheelEvent<HTMLElement>) => {
    if (scrollThrottle && modalVisible) {
      return;
    }

    const scrollingDown = event.deltaY > 0;
    handleScroll(scrollingDown);
  };

  const handleNextBook = () => {
    const next = isLastIndex > touchStartY;
    handleScroll(next);
  };

  const handlePrevBook = () => {
    const prev = isLastIndex <= touchStartY;
    handleScroll(prev);
  };

  const { description, title } = books[selectedIndex][locale];

  return (
    <MainTemplate>
      {modalVisible === selectedModal.modalVideo && (
        <Modal onClick={() => setModalVisible(null)}><Video books={books} selectedIndex={selectedIndex} /></Modal>
      )}
      {modalVisible === selectedModal.modalDescription && (
        <Modal onClick={() => setModalVisible(null)}>
          <SectionHeading>{books && title}</SectionHeading>
          <ContentContainer>{books && description}</ContentContainer>
        </Modal>
      )}
      <Helmet htmlAttributes={{ lang: `${locale}` }}>
        <title>Agnieszka Kazmierczyk</title>
        <meta name="description" content="Some content." />
      </Helmet>
      <HeroImage>
        <BackgroundImage books={books} selectedIndex={selectedIndex} />
        <Quote books={books} selectedIndex={selectedIndex} />
      </HeroImage>
      <ContentWrapper space>
        <ContentRow>
          <CoverImage books={books} selectedIndex={selectedIndex} />
          <ContentInner onWheel={handleWheel}>
            <ProgressBar startProgress={startProgress} />
            <Styled.ArrowNavWrapper>
              <Styled.ArrowNavContainer>
                <Styled.ArrowNav onClick={() => handlePrevBook()} aria-label={formatMessage({ id: 'prev.title' })}>
                  <ArrowIcon />
                </Styled.ArrowNav>
                <Styled.ArrowNav onClick={() => handleNextBook()} aria-label={formatMessage({ id: 'next.title' })}>
                  <ArrowIcon />
                </Styled.ArrowNav>
              </Styled.ArrowNavContainer>
            </Styled.ArrowNavWrapper>
            <SectionContent books={books} selectedIndex={selectedIndex} onClick={() => setModalVisible(selectedModal.modalVideo)} setModalVisible={() => setModalVisible(selectedModal.modalDescription)} replaceMessanger={replaceMessanger} />
          </ContentInner>
        </ContentRow>
      </ContentWrapper>
    </MainTemplate>
  );
};

export default Home;
