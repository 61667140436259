import styled from 'styled-components';
import { Colors, Media } from 'config/CONSTANTS';

export const ArrowNavWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;

  @media (min-width: ${Media.TABLET}) {
    margin-bottom: 0;
    margin-top: 30px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
  }

  @media (min-width: ${Media.DESKTOP}) {
    display: none;
  }
`;

export const ArrowNavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 200px;

  button {
    &:first-child {
      transform: rotate(90deg);
    }
    &:last-child {
      transform: rotate(-90deg);
    }
  }
`;

export const ArrowNav = styled.button`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid ${Colors.PRIMARY};
  background-color: transparent;
  outline: none;

  svg path {
    stroke: ${Colors.PRIMARY};
  }
`;
