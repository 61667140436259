import styled from 'styled-components';
import { Colors, Media } from 'config/CONSTANTS';

export const ProgressBarItem = styled.div`
  display: none;
  width: 3px;
  height: 100%;
  border-radius: 20px;
  background-color: ${Colors.PRIMARY};

  @media (min-width: ${Media.DESKTOP}) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(50%);
    transform-origin: top;
    transition: transform 0.3s ease;
  }
`;
