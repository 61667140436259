import React  from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import * as Styled from './SectionContentStyles';
import { SectionHeading } from 'components/SectionHeading/SectionHeading';
import {
  ButtonsWrapper,
  Button,
  ButtonText,
  ButtonIconArrow,
  ButtonsContainer,
  ButtonLink,
} from 'components/Button/Button';
import { BooksProps } from 'models/BooksProps';
import ArrowIcon from 'assets/arrow.svg';

const SectionContent: React.FC<BooksProps> = ({ books, selectedIndex, onClick, setModalVisible, replaceMessanger }) => {
  const { locale, formatMessage } = useIntl();
  const descriptionLimit = 300;

  return (
    <>
      {books.map((book, index: number) => {
        const { bookstore, review, trailer } = book;
        const { description, title } = book[locale];
        let isSelected = index === selectedIndex;

        const shortDescription = (
          <>
            {`${description.split('', descriptionLimit).join('')}... `}
            <Button link="true" onClick={setModalVisible} aria-label={formatMessage({ id: 'trailer.title' })}>
              <ButtonText>{formatMessage({ id: 'read.more' })}</ButtonText>
            </Button>
          </>
        );

        return (
          <Styled.ContentWrapperContainer key={index} animate={isSelected ? 'visible' : 'hidden'}>
            {title && description ? (
              <>
                <SectionHeading>{title}</SectionHeading>
                <Styled.ContentContainer> {description.length >= descriptionLimit ? shortDescription : description} </Styled.ContentContainer>
                {bookstore && (
                  <ButtonsWrapper>
                    <ButtonsContainer space tabletBlock>
                      <Button
                        as={ButtonLink}
                        href={`${bookstore}`}
                        icon="true"
                        target="_blank"
                        aria-label={formatMessage({ id: 'book.buy' })}
                      >
                        <ButtonText>{formatMessage({ id: 'book.buy' })}</ButtonText>
                        <ButtonIconArrow className="arrow--icon">
                          <ArrowIcon />
                        </ButtonIconArrow>
                      </Button>
                      <Button
                        as={ButtonLink}
                        href={`${replaceMessanger}`}
                        target="_blank"
                        aria-label={formatMessage({ id: 'book.buyAuthor' })}
                      >
                        <ButtonText>{formatMessage({ id: 'book.buyAuthor' })}</ButtonText>
                      </Button>
                    </ButtonsContainer>

                    <ButtonsContainer>
                      {review && (
                        <Button
                          as={ButtonLink}
                          href={`${review}`}
                          link="true"
                          target="_blank"
                          aria-label={formatMessage({ id: 'review.title' })}
                        >
                          <ButtonText>{formatMessage({ id: 'review.title' })}</ButtonText>
                        </Button>
                      )}
                      {trailer && (
                        <Button link="true" onClick={onClick} aria-label={formatMessage({ id: 'trailer.title' })}>
                          <ButtonText>{formatMessage({ id: 'trailer.title' })}</ButtonText>
                        </Button>
                      )}
                    </ButtonsContainer>
                  </ButtonsWrapper>
                )}
              </>
            ) : (
              <>
                <SectionHeading>Upss!</SectionHeading>
                <Styled.ContentContainer>{formatMessage({ id: 'error.msg' })}</Styled.ContentContainer>
              </>
            )}
          </Styled.ContentWrapperContainer>
        );
      })}
    </>
  );
};

export default SectionContent;
