import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import * as Styled from './QuoteStyles';
import { BooksProps } from 'models/BooksProps';
import QuoteIcon from 'assets/quote.svg';

const Quote: React.FC<BooksProps> = ({ books, selectedIndex }) => {
  const { locale } = useIntl();
  return (
    <Styled.QuoteWrapper>
      <QuoteIcon />
      {books.map((book: object, index: number) => {
        let isSelected = index === selectedIndex;
        return (
          <Styled.QuoteContent key={index} animate={isSelected ? 'visible' : 'hidden'}>
            {book[locale]?.quotation}
          </Styled.QuoteContent>
        );
      })}
    </Styled.QuoteWrapper>
  );
};

export default Quote;
