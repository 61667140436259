import styled from 'styled-components';
import { motion } from 'framer-motion';

const imageVisibleVariants = {
  visible: {
    opacity: 1,
    transition: { duration: 0.5, delayChilden: 0.2, staggerChildren: 0.1 },
  },
  hidden: {
    opacity: 0,
  },
};

export const BgImage = styled(motion.div).attrs(() => ({
  initial: 'hidden',
  variants: imageVisibleVariants,
}))`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
