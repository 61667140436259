import React from 'react';
import { GlobalStyles, MainContainer } from 'theme/GlobalStyles';
import Navigation from 'components/Navigation/Navigation';
import Footer from 'components/Footer/Footer';

const MainTemplate: React.FC = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <MainContainer>
        <Navigation />
        {children}
        <Footer />
      </MainContainer>
    </>
  );
};

export default MainTemplate;
